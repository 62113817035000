import React from 'react';
import { Route, Switch } from 'react-router-dom';

//import { hot } from 'react-hot-loader/root';
/*PV components imported below*/
/**-- */
import PVHome from './components/PVLandingPage/PVHome';
import PVSiteWrapper from './components/Shared/SiteWrapper/PVSiteWrapper';
import PVRouteChange from './components/Shared/SiteWrapper/PVRouteChange';
import PVErrorBoundary from './components/Shared/PVErrorBoundary';
import PVRouteAuth from './components/Shared/PVRouteAuth';

/*error pages*/
import PVErrorPage from './components/Shared/Content/PVErrorPage';


/* engage pages */
//const PVNewsletter = React.lazy(() => import('./components/Engage/PVNewsletter'));
//const PV40thAnniversary = React.lazy(() => import('./components/Engage/PV40thAnniversary'));
//const PVConnect = React.lazy(() => import('./components/Engage/PVConnect'));
/* corporate pages */
const PVOurPartners = React.lazy(() => import('./components/Company/PVOurPartners'));
const PVOurVendors = React.lazy(() => import('./components/Company/PVOurVendors'));
const PVContactUs = React.lazy(() => import('./components/Company/PVContactUs'));
const PVCareers = React.lazy(() => import('./components/Company/PVCareers'));
const PVAboutUs = React.lazy(() => import('./components/Company/PVAboutUs'));
const PVFacilities = React.lazy(() => import('./components/Company/PVFacilities'));
//const PVFindRep = React.lazy(() => import('./components/Company/PVFindRep'));
/* industry pages */
const PVSCL = React.lazy(() => import('./components/IndustryPages/PVSupplyChainLogistics'));
const PVBroadband = React.lazy(() => import('./components/IndustryPages/PVBroadband'));
//const PVVideo = React.lazy(() => import('./components/IndustryPages/PVVideo'));
//const PVWireI = React.lazy(() => import('./components/IndustryPages/PVWireInfrastructure'));
/****** partner pages *****/
const PVBecomeAnAuthorizedRetailer = React.lazy(() => import('./components/Partner/PVBecomeAnAuthorizedRetailer'));
const PVBecomeACustomer = React.lazy(() => import('./components/Partner/PVBecomeACustomer'));
const PVInternetServicesRetailerProgram = React.lazy(() => import('./components/Partner/PVInternetServicesRetailerProgram'));
const PVProgramTemplate = React.lazy(() => import('./components/PartnerwithPV/AuthorizedRetailerProgram/Programs/PVProgramTemplate'));

const ATTMDU = React.lazy(() => import('./components/Resources/PartnerResources/DIRECTV/MultiDwellingUnit'));
const ALDER = React.lazy(() => import('./components/Resources/PartnerResources/ALDER'));
const ViaSATISG = React.lazy(() => import('./components/Resources/PartnerResources/Viasat/InstallationServicesGroup'));
const ATTResidential = React.lazy(() => import('./components/Resources/PartnerResources/DIRECTV/Residential'));
const ATTLodging = React.lazy(() => import('./components/Resources/PartnerResources/DIRECTV/MSOLodgingandInstitution'));
const Frontier = React.lazy(() => import('./components/Resources/PartnerResources/Frontier'));
//const TMobile = React.lazy(() => import('./components/Resources/PartnerResources/TMobile'));
const Spectrum = React.lazy(() => import('./components/Resources/PartnerResources/Spectrum'));
const Kinetic = React.lazy(() => import('./components/Resources/PartnerResources/KineticbyWindstream/KineticbyWindstream'));
const ViasatResidentialandB = React.lazy(() => import('./components/Resources/PartnerResources/Viasat/ResidentialandBusiness'));
const ATTBusiness = React.lazy(() => import('./components/Resources/PartnerResources/DIRECTV/MSOBusiness'));
const SafeStreetsResidential = React.lazy(() => import('./components/Resources/PartnerResources/SafeStreets/Residential'));
const EarthLink = React.lazy(() => import('./components/Resources/PartnerResources/EarthLink'));
const ATTAlliance = React.lazy(() => import('./components/Resources/PartnerResources/DIRECTV/ATTAlliance'));
/*   Wireless Infrastructure  */
const PVWirelessInfrastructure = React.lazy(() => import('./components/Partner/PVWirelessInfrastructure'));








/* Landing page links */
const PVProductSolutions = React.lazy(() => import('./components/Shared/Content/PVProductSolutions'));

const PVInstallationServices = React.lazy(() => import('./components/Shared/Content/PVInstallationServices'));
const PVAcquistionServices = React.lazy(() => import('./components/Shared/Content/PVAcquistionServices'));


/* webstore pages */

const PVCart = React.lazy(() => import('./components/PVWebstore/PVCart'));
const PVCheckout = React.lazy(() => import('./components/PVWebstore/PVCheckout'));
const PVOrderHistory = React.lazy(() => import('./components/PVWebstore/PVOrderHistory'));
const PVCheckoutComplete = React.lazy(() =>
	import('./components/PVWebstore/PVCheckout/PVCheckoutComplete')
);
const PVProductList = React.lazy(() =>
	import('./components/PVWebstore/PVProductCategory/PVProductList')
);
const PVProductSearch = React.lazy(() =>
	import('./components/PVWebstore/PVProductCategory/PVProductSearch')
);
const PVProductDetail = React.lazy(() => import('./components/PVWebstore/PVProductDetail'));
const PVSubcategoryList = React.lazy(() =>
	import('./components/PVWebstore/PVProductCategory/PVSubcategoryList')
);
const PVProductFavoriteList = React.lazy(() =>
	import('./components/PVWebstore/PVProductCategory/PVProductFavoriteList')
);
const PVManageFavorites = React.lazy(() => import('./components/MyProfile/PVManageFavorites'));
const SliderExamples = React.lazy(() => import('./components/PVWebstore/SliderExamples'));
const PVFreeShippingDetails = React.lazy(() =>
	import('./components/PVWebstore/PVFreeShippingDetails')
);
/* resource pages */
const PVCatalogRequest = React.lazy(() => import('./components/Resources/PVCatalogRequest'));
const PVDigitalDatabase = React.lazy(() => import('./components/Resources/PVDigitalDatabase'));
const PVSafeStreets = React.lazy(() => import('./components/Resources/PartnerResources/PVSafeStreets'));
const PVAlder = React.lazy(() => import('./components/Resources/PartnerResources/PVAlder'));
const PVEarthLink = React.lazy(() => import('./components/Resources/PartnerResources/PVEarthLink'));
const PVOurBrands = React.lazy(() => import('./components/Resources/Products/OurBrands/PVOurBrands'));
const PVProductResourcesThankYouCall = React.lazy(() => import('./components/Resources/Products/PVProductResourcesThankYouCall'));
const PVProductResourcesThankYouEmail = React.lazy(() => import('./components/Resources/Products/PVProductResourcesThankYouEmail'));
const PVProductResources = React.lazy(() => import('./components/Resources/Products/PVProductResources'));
const PVVZWSmart = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/VZWSmart/PVVZWSmart')
);
const PVVZWSmartMonopoles = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/VZWSmart/PVVZWSmartMonopoles')
);
const PVVZWSmartSelfSupport = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/VZWSmart/PVVZWSmartSelfSupport')
);
const PVVZWSmartHardwareKits = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/VZWSmart/PVVZWSmartHardwareKits')
);
const PVClimbMaxx = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/ClimbMaxx/PVClimbMaxx')
);
const PVClimbMaxxStandards = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/ClimbMaxx/PVClimbMaxxStandards')
);
const PVClimbMaxxTemplate = React.lazy(() =>
	import('./components/Resources/Products/OurBrands/ClimbMaxx/PVClimbMaxxTemplate')
);
//const PVPRCenturyLink = React.lazy(() => import('./components/Resources/PartnerResources/CenturyLink'));
const PVPartnerResourcesTemplate = React.lazy(() =>
	import('./components/Resources/PartnerResources/PVPartnerResourcesTemplate')
);
/* mdp pages */
const MDPLiabilityReport = React.lazy(() => import('./components/MDP/Reports/MDPLiabilityReport'));
const MDPSubmittedVsActivated = React.lazy(() =>
	import('./components/MDP/Reports/MDPSubmittedVsActivated')
);
const MDPDisconnectedActivations = React.lazy(() =>
	import('./components/MDP/Reports/MDPDisconnectedActivations')
);
/* commercial pages */
const COMSubmittedVsActivated = React.lazy(() => import('./components/Commercial/Reports/COMSubmittedVsActivated'));
const COMDisconnectedActivations = React.lazy(() => import('./components/Commercial/Reports/COMDisconnectedActivations'));
/* my account pages */
const PVMyDashboard = React.lazy(() => import('./components/MyAccount/PVMyDashboard'));
const PVSupportTicket = React.lazy(() => import('./components/MyAccount/PVSupportTicket'));
const PVSalesTaxCertificates = React.lazy(() => import('./components/MyAccount/PVSalesTaxCertificates'));
const PVQuickOrder = React.lazy(() => import('./components/PVWebstore/PVQuickOrder/PVQuickOrder'));

const routes = [
	{ key: 0, path: '/Default.aspx', component: PVHome },
	{ key: 10, path: '/Broadband', component: PVBroadband },
	{ key: 20, path: '/SupplyChainLogistics', component: PVSCL },
	//{ key: 30, path: '/Video', component: PVVideo },
	//{ key: 40, path: '/WirelessInfrastructure', component: PVWireI },
	{ key: 50, path: '/Shop/Checkout/Paypal/:paypalAction', component: PVCheckout },
	{ key: 54, path: '/Shop/Checkout/Complete', component: PVCheckoutComplete },
	{ key: 58, path: '/Shop/Checkout', component: PVCheckout },
	{ key: 60, path: '/Shop/Cart', component: PVCart },
	{ key: 65, path: '/Shop/FreeShippingDetails', component: PVFreeShippingDetails },
	{ key: 69, path: '/PartnerwithPV/AuthorizedRetailerProgram/BecomeAnAuthorizedRetailer', component: PVBecomeAnAuthorizedRetailer },
	{ key: 70, path: '/PartnerwithPV/BecomeaCustomer', component: PVBecomeACustomer },
	{ key: 71, path: '/Partner/InternetServicesRetailerProgram', component: PVInternetServicesRetailerProgram },
	{ key: 72, path: '/PartnerwithPV/AuthorizedRetailerProgram/:programName', component: PVProgramTemplate },

	/* Wireless Infrastructure */
	{ key: 75, path: '/PartnerwithPV/TelecommunicationsInfrastructureProducts', component: PVWirelessInfrastructure },

	//{ key: 80, path: '/Engage/40thAnniversary', component: PV40thAnniversary },
	//{ key: 81, path: '/Engage/Connect', component: PVConnect },
	//{ key: 82, path: '/Engage/Newsletter', component: PVNewsletter },
	{
		key: 120,
		path: '/Company/OurVendors',
		component: PVOurVendors,
		displayBanner: true,
		bannerText: 'Vendors',
	},
	{
		key: 121,
		path: '/Company/OurPartners',
		component: PVOurPartners,
		displayBanner: true,
		bannerText: 'Partners',
	},
	{
		key: 301,
		path: '/Resources/PartnerResources/EarthLink/EarthLinkReporting',
		component: PVEarthLink,
	},
	{
		key: 302,
		path: '/Company/Careers',
		component: PVCareers,
	},
	{ key: 123, path: '/Company/ContactUs', component: PVContactUs },
	{ key: 124, path: '/Company/AboutUs', component: PVAboutUs },
	{ key: 125, path: '/Company/Facilities', component: PVFacilities },
	//{ key: 126, path: '/Company/FindRep', component: PVFindRep },
	{ key: 150, path: '/Shop/Category/:categoryName/:subcategory', component: PVProductList },
	{ key: 155, path: '/Shop/FavoriteList/:favoriteListId', component: PVProductFavoriteList },
	{ key: 157, path: '/MyProfile/ManageFavorites', component: PVManageFavorites },
	{ key: 160, path: '/Shop/Product/:itemID', component: PVProductDetail },
	{ key: 170, path: '/Shop/Category/:categoryName', component: PVSubcategoryList },
	{ key: 180, path: '/Shop/Sliders', component: SliderExamples },
	{ key: 190, path: '/Shop/OrderHistory', component: PVOrderHistory },
	{ key: 192, path: '/Resources/PartnerResources/DIRECTV/MultiDwellingUnit', component: ATTMDU },
	{ key: 193, path: '/Resources/PartnerResources/ALDER/AlderResources', component: ALDER },

	{ key: 194, path: '/Resources/PartnerResources/Viasat/InstallationServicesGroup', component: ViaSATISG },
	{ key: 294, path: '/Resources/PartnerResources/DIRECTV/Residential', component: ATTResidential },
	{ key: 295, path: '/Resources/PartnerResources/DIRECTV/MSOLodgingandInstitution', component: ATTLodging },
	{ key: 296, path: '/Resources/PartnerResources/Frontier', component: Frontier },
	//{ key: 800, path: '/Resources/PartnerResources/T-Mobile', component: TMobile },
	{ key: 801, path: '/Resources/PartnerResources/Spectrum', component: Spectrum },
	{ key: 182, path: '/Resources/PartnerResources/ATTBusinessAlliance/ATTAlliance', component: ATTAlliance },
	{ key: 297, path: '/Resources/PartnerResources/KineticbyWindstream/KineticbyWindstream', component: Kinetic },
	{ key: 298, path: '/Resources/PartnerResources/Viasat/ResidentialandBusiness', component: ViasatResidentialandB },
	{ key: 299, path: '/Resources/PartnerResources/DIRECTV/MSOBusiness', component: ATTBusiness },
	{ key: 300, path: '/Resources/PartnerResources/SafeStreets/Residential', component: SafeStreetsResidential },
	{ key: 301, path: '/Resources/PartnerResources/EarthLink/EarthLinkResources', component: EarthLink },



	{ key: 195, path: '/Resources/Products/OurBrands/OurBrands', component: PVOurBrands },

	{ key: 303, path: '/Resources/ProductResources/Resources/ThankYou/Call', component: PVProductResourcesThankYouCall },
	{ key: 304, path: '/Resources/ProductResources/Resources/ThankYou/Email', component: PVProductResourcesThankYouEmail },
	{ key: 305, path: '/Resources/ProductResources/Resources', component: PVProductResources },

	{
		key: 196,
		path: '/Resources/Products/OurBrands/VZWSmart/Monopoles',
		component: PVVZWSmartMonopoles,
	},
	{
		key: 197,
		path: '/Resources/Products/OurBrands/VZWSmart/SelfSupport',
		component: PVVZWSmartSelfSupport,
	},
	{
		key: 198,
		path: '/Resources/Products/OurBrands/VZWSmart/HardwareKits',
		component: PVVZWSmartHardwareKits,
	},
	{ key: 199, path: '/Resources/Products/OurBrands/VZWSmart', component: PVVZWSmart },
	{ key: 252, path: '/Resources/Products/OurBrands/ClimbMaxx/Standards', component: PVClimbMaxxStandards },
	{
		key: 253,
		path: '/Resources/Products/OurBrands/ClimbMaxx/:category',
		component: PVClimbMaxxTemplate,
	},
	{ key: 254, path: '/Resources/Products/OurBrands/ClimbMaxx', component: PVClimbMaxx },
	{ key: 201, path: '/Resources/Catalogs', component: PVCatalogRequest },   /*changed as per #7407 */
	{ key: 202, path: '/Resources/DigitalDatabase', component: PVDigitalDatabase },  /* changed as per #7407 */
	{
		key: 204,
		path: '/Resources/PartnerResources/SafeStreets/ADTSalesReporting',
		component: PVSafeStreets,
	},
	{
		key: 205,
		path: '/Resources/PartnerResources/ALDER/AlderReporting',
		component: PVAlder,
	},
	{
		key: 206,
		path: '/Resources/PartnerResources/:partnerName/:subPartnerName',
		component: PVPartnerResourcesTemplate,
	},
	{
		key: 207,
		path: '/Resources/PartnerResources/:partnerName',
		component: PVPartnerResourcesTemplate,
	},
	{
		key: 208,
		path: '/NIMS/Reports/MDPLiabilityReport',
		component: MDPLiabilityReport,
	},
	{
		key: 209,
		path: '/NIMS/Reports/SubmittedVsActivated',
		component: MDPSubmittedVsActivated,
	},
	{
		key: 210,
		path: '/NIMS/Reports/DisconnectedActivations',
		component: MDPDisconnectedActivations,
	},
	{
		key: 211,
		path: '/NIMS/Reports/COMSubmittedVsActivated',
		component: COMSubmittedVsActivated,
	},
	{
		key: 212,
		path: '/NIMS/Reports/COMDisconnectedActivations',
		component: COMDisconnectedActivations,
	},
	{
		key: 250,
		path: '/MyAccount/MyDashboard',
		component: PVMyDashboard,
	},
	{
		key: 251,
		path: '/MyAccount/SupportTicket',
		component: PVSupportTicket,
	},
	{
		key: 252,
		path: '/Shop/QuickOrder',
		component: PVQuickOrder,
	},
	{
		key: 260,
		path: '/MyAccount/SalesTaxCertificates',
		component: PVSalesTaxCertificates,
	},

	{ key: 980, path: '/Products', component: PVProductSearch },
	{ key: 999, path: '/Error/:code', component: PVErrorPage },

	/*  Landing pages#7483 */
	{ key: 400, path: '/Components/Shared/Content/PVProductSolutions', component: PVProductSolutions },
	{ key: 401, path: '/Components/Shared/Content/PVInstallationServices', component: PVInstallationServices },
	{ key: 402, path: '/Components/Shared/Content/PVAcquistionServices', component: PVAcquistionServices },





];

const App = () => {
	const HomeComponent = PVRouteChange(PVHome);
	const PageNotFound = PVRouteChange(PVErrorPage);
	return (
		<div>
			<Switch>
				<Route
					exact
					path="/"
					render={() => (
						<PVSiteWrapper>
							<HomeComponent />
						</PVSiteWrapper>
					)}
				/>
				<Route
					path="/Error/:code"
					render={() => (
						<PVSiteWrapper>
							<PVErrorPage />
						</PVSiteWrapper>
					)}
				/>
				{routes.map((route) => {
					const { component } = route;
					const RouteComponent = PVRouteChange(component);
					const componentProps = route.componentProps || {};
					return (
						<PVRouteAuth path={route.path} key={route.key}>
							<Route
								path={route.path}
								render={({ match, location, history }) => (
									<PVSiteWrapper
										displayFooter={
											route.displayFooter ? true : route.displayFooter
										}
										displayBanner={route.displayBanner}
										bannerText={route.bannerText}
									>
										<PVErrorBoundary history={history}>
											<RouteComponent
												{...componentProps}
												match={match}
												location={location}
												history={history}
											/>
										</PVErrorBoundary>
									</PVSiteWrapper>
								)}
							/>
						</PVRouteAuth>
					);
				})}
				<Route
					render={() => (
						<PVSiteWrapper>
							<PageNotFound match={{ params: { code: '404' } }} />
						</PVSiteWrapper>
					)}
				/>
			</Switch>
		</div>
	);
};
//export default hot(App);
export default App;
